import React, { useState, useEffect, useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./Carousel.css";
import http from "../lib/http";
import TimelineItem from "./TimelineItem";

export default function Carousel() {
  const [timeline, setTimeline] = useState([
    {
      index: 1,
      year: 2021,
      event: "",
      video:
        "https://www.youtube.com/embed/yAn-Fw8ZW_Y?si=WpqQy1WyvVFeOLfue&amp;controls=0&amp;nablejsapi=1",
      imageURL: "",
      alt: "",
    },
  ]);
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/events/`);
      setTimeline(data.data.events);
    }
    fetchData();
  });
  // console.log(timeline[0].year)

  const {
    carouselIndex, // eslint-disable-next-line
    setCarouselIndex,
    itemChosen, // eslint-disable-next-line
    setItemChosen,
  } = useContext(TopicContext);
  const vid = timeline[carouselIndex - 1].video;
  return (
    <div className="carousel">
      <iframe
        id="video"
        className={itemChosen ? "history" : "history hide"}
        width="100%"
        height="100%"
        src={vid ? vid : null}
        title="YouTube video player"
      ></iframe>
      <div className="carousel-container">
        <button className={`${carouselIndex === 1 ? "hide" : ""} arrow left`}>
          <img
            alt="left arrow"
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
          />
        </button>
        <button className={`${carouselIndex === 9 ? "hide" : ""} arrow right`}>
          <img
            alt="right arrow"
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Right%20Arrow.png"
          />
        </button>
        <div
          className="inner"
          style={{
            transform: `translateX(${-(carouselIndex * 48) + 74}%)`,
          }}
        >
          <TimelineItem {...timeline[0]} />
          <TimelineItem {...timeline[1]} />
          <TimelineItem {...timeline[2]} />
          <TimelineItem {...timeline[3]} />
          <TimelineItem {...timeline[4]} />
          <TimelineItem {...timeline[5]} />
          <TimelineItem {...timeline[6]} />
          <TimelineItem {...timeline[7]} />
          <TimelineItem {...timeline[8]} />
        </div>
      </div>
    </div>
  );
}
